:root {
  --color-1 : #31255a ;
  --color-2 : #8fe0ff ;
  --color-3 : #75b4e3 ;
  --color-4 : #54416d ;
  --color-5 :	#847bb8 ;
}


body{
  margin: 20px;
  background-color: var(--color-2);
  height: 100% ;
}

.app_container {
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.left_side {
  color: white;
  width: 30%;
  text-align: center;
  background-color: var(--color-4);
}

.right_side {
  width: 70%;
  background-color: var(--color-5);
}

.form_container {
  margin: 30px 0;
}

.input_area {
  font-size: 24px;
  width: 90%;
  height: 30px;
  padding: 5px;
  border: none;
  background-color: var(--color-3);
  outline: none;
  text-align: center;
}

.img_container {
  margin-bottom: 5px;
}

.temp_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.temp_degree {
  font-size: 50px;
}

.fells_like {
  font-size: 1.2rem;
  margin-top: -5px;
  border-bottom: 2px solid var(--color-1);
  margin: 10px 30px;
}

.img_weather {
  width: 40%;
  height: 40%;
}


.img_degree {
  width: 25px;
  height: 25px;
  
}

.day {
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin: 20px;
  border-bottom: 1px solid var(--color-1);
  
}

.description {
  font-size: 24px;
  background-color: var(--color-4);
margin: 10px;  
}

.max_min {
  display: flex;
  margin: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}

.min {
  margin: 2px;;
}

.max {
  margin: 2px;
  
}

.img {
  margin: 10px;
  width: 10%;
  height: 10%;
}
 
.day{
  padding: 5px;
}

.timezone {
  font-size: 1.2rem;
}

.city_name {
  font-size: 1.5rem;
  margin: 10px;
  padding: 10px;
  border: 1px dashed var(--color-1);
  font-weight: bold;
}

/* navbar css */

.navbar_container {
  margin: 10px 20px;
  display: flex;
  justify-content: flex-end;
}

.c_or_f {
  width: 30px;
  height: 30px;
cursor: pointer;
margin:  0 10px;
}

.github{
  width: 30px;
  height: 30px;
}

.github:hover {
  transform: scale(1.2);
}

/* card detail */

.dailyCard {
  justify-content: space-around;
  height: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.card_container {
  display: flex;
  flex-wrap: wrap;
 text-align: center;
}
.weather_container {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: white;
  margin: 10px 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  height: max-content;
  box-shadow: 0px 0px 18px 0px var(--color-2);
  width: 110px;
 }
.card_title {
  font-weight: bold;
}
.card_img {
  margin: -5px 0;
  width: 90px;
  height: 90px;
}
.card_temp {
font-size: 24px;
}
.card_min_max {
  display: flex;
  font-size: 12px;
}
.card_min {
  margin-right: 8px;
}
